/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "swiper/swiper-bundle.min.css";

/* @import "swiper/swiper.scss";
@import "swiper/scss/autoplay";
@import "swiper/scss/pagination";
@import "@ionic/angular/css/ionic-swiper"; */


/* // EXTRA GLOBAL STYLES
// Add custom Ionic Colors
@import "theme/custom-ion-colors.scss";
// Add base app styles
@import "theme/app-defaults.scss";
// Add base shell styles
@import "theme/shell-defaults.scss";
// Add base flex styles
@import "theme/flex-structures.scss"; */


:root {
    /* --gradient: linear-gradient( 45deg, #855ec2, #d65db1, #ff6f91, #ff9671, #ffc75f, #f9f871); */
    --gradient: linear-gradient(45deg, #855ec2, #d65db1, #ff6f91);
    --primary: #855ec2;
}


google-map {
    height: 100%;
    width: 100%;
}

.miradorLabel {
    color: white;
    background-color: #454545;
    text-align: center;
    white-space: nowrap;
    padding: 4px 8px;
    border-radius: 2px;
    box-shadow: 0px 2px 50px rgb(0 0 0 / 28%);

    &.Comercial {
        border-bottom: 2px solid #ffde59;
    }

    &.Publico {
        border-bottom: 2px solid #3bc275;
    }

    &.Mall {
        border-bottom: 2px solid #2e91f7;
    }

    &.Rooftop {
        border-bottom: 2px solid #9761d3;
    }
}

.linkLabel::before {
    content: attr(data-before);
    color: white;
    background-color: #242424;
    text-align: center;
    white-space: nowrap;
    padding: 4px 8px;
    border-radius: 2px;
    display: inline-block;
    margin-top: 0px;
    box-shadow: 0px -15px 50px rgb(0 0 0 / 15%);
}

.Comercial::before {
    border-bottom: 2px solid #ffde59;
}

.Publico::before {
    border-bottom: 2px solid #3bc275;
}

.Mall::before {
    border-bottom: 2px solid #2e91f7;
}

.Rooftop::before {
    border-bottom: 2px solid #9761d3;

    /* background: linear-gradient(orange, violet);
    height: 20px;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/miradores-ac574.appspot.com/o/logo-cover%2FCOVER-VISTAVUELO.png?alt=media&token=30eb4d72-2896-4437-b551-9a376e3a9a38);
    border: 3px solid;
    background-size: cover;
    width: 20px;
    border-radius: 50%; */

}

.Hospedaje::before {
    border-bottom: 2px solid rgb(238, 28, 36);
}


.filter-body {
    background-color: white;
    border-radius: 8px;

    >.filter-label {
        padding-left: 11px;
        margin: 0px;
        padding-top: 2px;
    }

    nz-select {
        margin: 0 12px 12px 0;

        >nz-select-top-control {
            min-width: 125px;
            border-radius: 8px !important;
        }
    }
}



/* ion-card {
    width: 150px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    background-color: white;
    box-shadow: 0 1px 23px rgb(68 68 68 / 5%);
    margin: 12px 8px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    flex-grow: 1;
} */

$backGradColor: linear-gradient(to left, #00C853, #B2FF59),
    linear-gradient(to right, red, orange),
    linear-gradient(to left, #743ad5, #d53a9d),
;



@keyframes pulse-ring {
    0% {
        transform: scale(.63);
    }

    80%,
    100% {
        opacity: 0;
    }
}

@keyframes pulse-dot {
    0% {
        transform: scale(.8);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(.8);
    }
}

body#ionic-body {
    &.instagram-browser {
        height: 95%;
    }
}

.img_logo {
    height: 32px;
    content: var(--img-logo);
}

ion-toast.customToast {
    transform: translateY(-80px);
}


ion-toast.customToast::part(button) {
    color: #3880ff;
}



.transparent-modal {
    --background: rgba(44, 39, 45, 0.2);
    color: white !important;

    &::part(content) {
        backdrop-filter: blur(12px);
    }
}






jeep-photoviewer {
    background-color: aqua;

    .photoviewer-container {
        background-color: aqua;
    }

    :host ::ng-deep .photoviewer-container {
        background-color: aqua;
        line-height: 1;
        height: 30px;
    }
}


.photoviewer-container {
    background-color: aqua;
}

:host ::ng-deep .photoviewer-container {
    background-color: aqua;
    line-height: 1;
    height: 30px;
}


.photoviewer-container {
    background-color: aqua;

    .carousel-item {
        border-right: none;
    }

    .photobuttons-container {
        background-color: transparent;
    }

}

@keyframes bounceIn {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }

    60% {
        transform: scale(1.2);
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}

.medal-alert {
    animation: bounceIn 0.8s ease;
}

.modalCountries {

    ion-radio {
        opacity: 1;
    }
    
    label {
        opacity: 1;
    }


}

:host ::ng-deep label {
    opacity: 1;
}